export const FES_PLOT_DATA_SIZE = 200;
export const DEFAULT_EDGE_COORDINATES = {
    minX: 0,
    maxX: 0,
    minY: 0,
    maxY: 0,
};
export const BINARY_DASHED_LINE_LABEL = '1';
export const CONTINUOUS_DASHED_LINE_LABEL = '0';
export const MOST_SIGNIFICANT_ASSOCIATIONS_THRESHOLD = 0.0001;
export const PHENOTYPE_ID_LOADING_MESSAGE = 'Loading phenotype id...';
