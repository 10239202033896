var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ELevelResult } from 'Shared/types';
import { encodeStringToBase64, isBase64String, isValidUuid, isValidV4Uuid, } from 'Common/utils';
import { logger, ELogEnvironment } from 'Common/utils/logger/logger';
import { CollapsingModelsService } from 'Common/services';
import { PHENOTYPE_ID_LOADING_MESSAGE } from 'FESView/consts';
import { CollapsingModels, PhenotypeStats, Phenotype, PhenotypeId, } from 'API/resources';
import { HTTPClientError, HTTPNotFoundError, HTTPServerError } from 'API/errors';
import { getSelectedDatasetProviderId } from 'Datasets/store/selectors';
import { fetchAPIData as fetchDatasetsData } from 'Datasets/store/actions';
import { ActionError } from 'Store/errors';
import { setDataExists, setLoadingMessage } from './common';
import { handlePhenotypeRequestError } from '../utils';
export const typePrefix = 'fesView/fetchAPIData';
export const fetchAPIData = createAsyncThunk(typePrefix, ({ encodedPhenotypeName, phenotypeId: phenotypeIdParam, datasetVersionId, datasetName, }, { dispatch, getState, rejectWithValue, signal, }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        let phenotypeId = phenotypeIdParam;
        if (isValidUuid(encodedPhenotypeName)) {
            phenotypeId = encodedPhenotypeName;
        }
        else if (!isValidV4Uuid(phenotypeId)) {
            dispatch(setLoadingMessage(PHENOTYPE_ID_LOADING_MESSAGE));
            const phenotypeNameBase64 = isBase64String(encodedPhenotypeName)
                ? encodedPhenotypeName
                : encodeStringToBase64(encodedPhenotypeName);
            const phenotypeIdResponse = yield PhenotypeId.resource
                .getData({ phenotypeNameBase64 }, undefined, undefined, signal);
            phenotypeId = phenotypeIdResponse || '';
        }
        dispatch(setLoadingMessage('Loading datasets...'));
        const fetchDatasetsDataAction = dispatch(fetchDatasetsData({
            datasetType: 1 /* Phenotype */,
            resourceId: phenotypeId,
            datasetVersionId,
        }));
        /**
         * Instead of passing signal down to each action
         * abort them via callback invoked by the current signal
         */
        // eslint-disable-next-line no-param-reassign
        signal.onabort = () => {
            fetchDatasetsDataAction.abort();
        };
        const { payload: datasetsResult } = yield fetchDatasetsDataAction;
        const datasetsError = datasetsResult;
        if (datasetsError.type !== undefined) {
            throw new ActionError(datasetsError.type);
        }
        const selectedProviderId = getSelectedDatasetProviderId(getState());
        if (!selectedProviderId) {
            throw new ActionError(0 /* NotFound */);
        }
        const phenotypeResourceGLR = Phenotype.resource(ELevelResult.Gene);
        const phenotypeResourceVLR = Phenotype.resource(ELevelResult.Variant);
        dispatch(setLoadingMessage('Loading collapsing models...'));
        const collapsingModelsData = yield CollapsingModels
            .resource
            .getData({ datasetVersionId });
        dispatch(setDataExists(true));
        if (!collapsingModelsData.length) {
            throw new HTTPNotFoundError();
        }
        const collapsingModelIdsGLR = CollapsingModelsService.getCollapsingModelIds(collapsingModelsData.filter((model) => model.name !== 'syn'), ELevelResult.Gene);
        const collapsingModelIdsVLR = CollapsingModelsService.getCollapsingModelIds(collapsingModelsData, ELevelResult.Variant);
        const phenotypeRequestsGLR = phenotypeResourceGLR.getData({
            phenotypeId,
            datasetVersionId,
        }, {
            collapsingModelId: collapsingModelIdsGLR,
        }, undefined, signal);
        const phenotypeRequestsVLR = phenotypeResourceVLR.getData({
            phenotypeId,
            datasetVersionId,
        }, {
            collapsingModelId: collapsingModelIdsVLR,
        }, undefined, signal);
        const phenotypeStatsRequestGLR = PhenotypeStats
            .resource(ELevelResult.Gene)
            .getData({
            phenotypeId,
            datasetVersionId,
        }, undefined, undefined, signal);
        dispatch(setLoadingMessage('Loading associations and statistics data...'));
        const [phenotypeDataGLR, phenotypeDataVLR, phenotypeStats,] = yield Promise.all([
            phenotypeRequestsGLR.catch(handlePhenotypeRequestError),
            phenotypeRequestsVLR.catch(handlePhenotypeRequestError),
            phenotypeStatsRequestGLR,
        ]);
        if (!(Object.keys(phenotypeDataGLR.associations).length
            || Object.keys(phenotypeDataVLR.associations).length)) {
            throw new HTTPNotFoundError();
        }
        return {
            datasetName,
            phenotypeDataGLR,
            phenotypeDataVLR,
            collapsingModelsData,
            phenotypeStats,
        };
    }
    catch (err) {
        if (signal.aborted) {
            return rejectWithValue(ActionError.createSerialized(4 /* Aborted */));
        }
        if (err instanceof ActionError) {
            return rejectWithValue(ActionError.createSerialized(err.type));
        }
        if (err instanceof HTTPClientError || err instanceof HTTPNotFoundError) {
            return rejectWithValue(ActionError.createSerialized(1 /* EmptyData */));
        }
        if (err instanceof HTTPServerError) {
            return rejectWithValue(ActionError.createSerialized(5 /* ServerError */));
        }
        logger.error(err.message, ELogEnvironment.All);
        return rejectWithValue(ActionError.createSerialized());
    }
}));
