import { escapeRegExp } from 'Shared/utils';
import { ETraitsTypes } from 'Shared/types';
import { filterByDirectionOfEffect } from 'Common/utils/filterByDirectionOfEffect';
/**
 * Finds most significant association with current filters
 *
 * @param data - Most significant model data for phenotype
 * @param associationCollapsingModelId - Id of collapsing model for an association
 * @param traitsType - Traits type
 * @param selectedCollapsingModelIds - Collapsing models selected in Global filters
 * @param selectedCategoriesNames - Phenotypic categories selected in Global filters
 * @param maxPValue - pValue provided in Global filters
 * @param isDirectionOfEffectFilterActive - Boolean defining if direction of effect filter
 *                                          is active
 * @returns Returns `true`, if phenotype has most significant model
 *          with current filters, otherwise `false`
 */
const filterByMostSignificantModel = (data = [], associationCollapsingModelId, traitsType, selectedCollapsingModelIds, selectedCategoriesNames, maxPValue, isDirectionOfEffectFilterActive) => {
    const filteredCollapsingModelIds = data.reduce((acc, item) => {
        const { collapsingModelId, categoryName, pvalue, effectSize, oddsRatio, } = item;
        if (selectedCollapsingModelIds.has(collapsingModelId)
            && selectedCategoriesNames.has(categoryName)
            && (maxPValue === null || pvalue <= maxPValue)
            && (!isDirectionOfEffectFilterActive || filterByDirectionOfEffect(traitsType === ETraitsTypes.Binary
                ? oddsRatio
                : effectSize, traitsType))) {
            acc.push(collapsingModelId);
        }
        return acc;
    }, []);
    return filteredCollapsingModelIds[0] === (associationCollapsingModelId);
};
/**
 * Transform variant associations based on provided filters
 *
 * @param o - Params required for filtering
 * @param o.associations - Associations collection
 * @param o.filters - Applied filters
 * @param o.phenotypesMostSignificantModels - Collection of most significant models data
 *                                            per phenotype
 * @returns Updated (filtered) data Ids
 */
export const getFilteredData = ({ associations, filters, phenotypesMostSignificantModels, }) => {
    const { collapsingModels, categories, phenotype, maxPValue, isMostSignificantModel, isDirectionOfEffectFilterActive, } = filters;
    const selectedCollapsingModelIds = new Set(collapsingModels.map((model) => model.id));
    const selectedCategoriesNames = new Set(categories.map((category) => category.name));
    let filteredAssociations = associations.order
        .map((id) => associations.collection[id])
        .filter(({ collapsingModel }) => selectedCollapsingModelIds.has(collapsingModel.id))
        .filter(({ phenotypicCategory }) => selectedCategoriesNames.has(phenotypicCategory.name))
        .filter((association) => (!isDirectionOfEffectFilterActive || filterByDirectionOfEffect(association.traitsType === ETraitsTypes.Binary
        ? association.oddsRatio
        : association.effectSize, association.traitsType)))
        .filter((association) => (!isMostSignificantModel || filterByMostSignificantModel(phenotypesMostSignificantModels[association.phenotype.name], association.collapsingModel.id, association.traitsType, selectedCollapsingModelIds, selectedCategoriesNames, maxPValue, isDirectionOfEffectFilterActive)));
    if (phenotype !== null) {
        filteredAssociations = filteredAssociations.filter((association) => new RegExp(escapeRegExp(phenotype), 'i').test(association.phenotype.name));
    }
    if (maxPValue !== null) {
        filteredAssociations = filteredAssociations.filter(({ pvalue }) => pvalue <= maxPValue);
    }
    return filteredAssociations.map(({ id }) => id);
};
