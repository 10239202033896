/**
 * getResetButtonStatus:
 *
 * @param filters Object with changed filters properties
 * @param collapsingModels Array of initial collapsing models
 * @param categories Array of initial categories
 * @returns Boolean: filters changed status
 */
export const getResetButtonStatus = (filters, collapsingModels, categories, consequenceTypes) => {
    const isCategoriesNotChanged = filters.categories
        ? filters.categories.length === (categories === null || categories === void 0 ? void 0 : categories.length)
        : true;
    const isModelsNotChanged = filters.collapsingModels
        ? filters.collapsingModels.length === (collapsingModels === null || collapsingModels === void 0 ? void 0 : collapsingModels.length)
        : true;
    const isFiltersNotChanged = !([
        filters.gene, filters.variant, filters.maxPValue, filters.phenotype,
    ].find((filter) => filter !== undefined && filter !== null) !== undefined);
    const isConsequenceTypesNotChanged = filters.consequenceTypes && consequenceTypes
        ? filters.consequenceTypes.length === consequenceTypes.length
        : true;
    return isCategoriesNotChanged
        && isFiltersNotChanged
        && isModelsNotChanged
        && isConsequenceTypesNotChanged
        && !filters.isMostSignificantModel
        && !filters.isDirectionOfEffectFilterActive;
};
