import { ETraitsTypes } from 'Shared/types';
import { variantIdMock, variantNameMock, variantTypeMock, geneIdMock, geneNameMock, phenotypeIdMock, phenotypeNameMock, phenotypicCategoryIdMock, phenotypicCategoryNameMock, phenotypicCategoryShortNameMock, } from 'Common/mocks';
import { generateData, createMockedURL, getMock } from 'API/utils';
import { collapsingModelsIds } from 'API/resources/collapsingModels';
import { FinnGenDatasetVersionId, UKBDatasetVersionId } from '../datasets';
import { collapsingModelsResponseMock } from '../collapsingModels/mocks';
import { variantResourceVLR } from './resource';
// Collections
export const variantBinaryCollectionResponseMock = {
    [collapsingModelsResponseMock[0].id]: generateData([
        {
            phenotype_id: phenotypeIdMock,
            phenotype_name: phenotypeNameMock,
            category_id: phenotypicCategoryIdMock,
            category_name: phenotypicCategoryNameMock,
            category_short_name: phenotypicCategoryShortNameMock,
            pvalue: '0.0001706196132176885',
            stats: {
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                gene_name: 'AAAS',
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 0.31231,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
            },
        },
        {
            phenotype_name: '41202#C508#C50.8 Overlapping lesion of breast',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_name: 'Chapter XIV Diseases of the genitourinary system',
            category_short_name: 'FinnGen Genitourinary system',
            pvalue: '0.000151570655403801',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 1,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                gene_name: 'AAAS',
            },
        },
        {
            phenotype_name: '41202#C508#C50.8 Overlapping lesion of breast',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_name: 'Chapter XIV Diseases of the genitourinary system',
            category_short_name: 'FinnGen Genitourinary system',
            pvalue: '0.000151570655403801',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 0.31231,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                gene_name: 'AAAS',
            },
        },
    ], 1),
    [collapsingModelsResponseMock[1].id]: generateData([
        {
            phenotype_name: '41202#C508#C50.8 Overlapping lesion of breast',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_name: 'Chapter XIV Diseases of the genitourinary system',
            category_short_name: 'FinnGen Genitourinary system',
            pvalue: '0.000151570655403801',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 2.31,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                gene_name: 'AAAS',
            },
        },
        {
            phenotype_name: '41202#C508#C50.8 Overlapping lesion of breast',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_name: 'Chapter XIV Diseases of the genitourinary system',
            category_short_name: 'FinnGen Genitourinary system',
            pvalue: '0.000151570655403801',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 0.31231,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                gene_name: 'AAAS',
            },
        },
        {
            phenotype_name: '41202#C508#C50.8 Overlapping lesion of breast',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_name: 'Chapter XIV Diseases of the genitourinary system',
            category_short_name: 'FinnGen Genitourinary system',
            pvalue: '0.000151570655403801',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 1,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                gene_name: 'AAAS',
            },
        },
    ], 1),
    [collapsingModelsResponseMock[2].id]: generateData([
        {
            phenotype_name: '41202#C508#C50.8 Overlapping lesion of breast',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_name: 'Chapter XIV Diseases of the genitourinary system',
            category_short_name: 'FinnGen Genitourinary system',
            pvalue: '0.000151570655403801',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 2.331,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                gene_name: 'AAAS',
            },
        },
        {
            phenotype_name: '41202#C508#C50.8 Overlapping lesion of breast',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_name: 'Chapter XIV Diseases of the genitourinary system',
            category_short_name: 'FinnGen Genitourinary system',
            pvalue: '0.000151570655403801',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 0.31,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                gene_name: 'AAAS',
            },
        },
        {
            phenotype_name: '41202#C508#C50.8 Overlapping lesion of breast',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_name: 'Chapter XIV Diseases of the genitourinary system',
            category_short_name: 'FinnGen Genitourinary system',
            pvalue: '0.000151570655403801',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 1.231,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                gene_name: 'AAAS',
            },
        },
    ], 1),
    [collapsingModelsResponseMock[3].id]: [],
    [collapsingModelsResponseMock[4].id]: [],
    [collapsingModelsResponseMock[5].id]: [],
};
export const variantContinuousCollectionResponseMock = {
    [collapsingModelsResponseMock[0].id]: generateData([
        {
            phenotype_id: phenotypeIdMock,
            phenotype_name: phenotypeNameMock,
            category_id: phenotypicCategoryIdMock,
            category_name: phenotypicCategoryNameMock,
            category_short_name: phenotypicCategoryShortNameMock,
            pvalue: '0.0001706196132176885',
            stats: {
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                gene_name: 'AAAS',
                no_samples: 12300,
                no_aa_genotypes: 100,
                no_ab_genotypes: 200,
                no_bb_genotypes: 300,
                maf: 13.33,
                ab_or_bb_genotypes: 14.34,
                bb_genotypes: 44.44,
                effect_size: -54,
                effect_size_standard_error: 100,
                effect_size_lci: 20.22,
                effect_size_uci: 23.23,
            },
        },
        {
            phenotype_name: '41202#C508#C50.8 Overlapping lesion of breast',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_name: 'Chapter XIV Diseases of the genitourinary system',
            category_short_name: 'FinnGen Genitourinary system',
            pvalue: '0.000151570655403801',
            stats: {
                no_samples: 12300,
                no_aa_genotypes: 100,
                no_ab_genotypes: 200,
                no_bb_genotypes: 300,
                maf: 13.33,
                ab_or_bb_genotypes: 14.34,
                bb_genotypes: 44.44,
                effect_size: 0,
                effect_size_standard_error: 100,
                effect_size_lci: 20.22,
                effect_size_uci: 23.23,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                gene_name: 'AAAS',
            },
        },
        {
            phenotype_name: '41202#C508#C50.8 Overlapping lesion of breast',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_name: 'Chapter XIV Diseases of the genitourinary system',
            category_short_name: 'FinnGen Genitourinary system',
            pvalue: '0.000151570655403801',
            stats: {
                no_samples: 12300,
                no_aa_genotypes: 100,
                no_ab_genotypes: 200,
                no_bb_genotypes: 300,
                maf: 13.33,
                ab_or_bb_genotypes: 14.34,
                bb_genotypes: 44.44,
                effect_size: 54,
                effect_size_standard_error: 100,
                effect_size_lci: 20.22,
                effect_size_uci: 23.23,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                gene_name: 'AAAS',
            },
        },
    ], 1),
    [collapsingModelsResponseMock[1].id]: generateData([
        {
            phenotype_name: '41202#C508#C50.8 Overlapping lesion of breast',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_name: 'Chapter XIV Diseases of the genitourinary system',
            category_short_name: 'FinnGen Genitourinary system',
            pvalue: '0.000151570655403801',
            stats: {
                no_samples: 12300,
                no_aa_genotypes: 100,
                no_ab_genotypes: 200,
                no_bb_genotypes: 300,
                maf: 13.33,
                ab_or_bb_genotypes: 14.34,
                bb_genotypes: 44.44,
                effect_size: -54,
                effect_size_standard_error: 100,
                effect_size_lci: 20.22,
                effect_size_uci: 23.23,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                gene_name: 'AAAS',
            },
        },
        {
            phenotype_name: '41202#C508#C50.8 Overlapping lesion of breast',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_name: 'Chapter XIV Diseases of the genitourinary system',
            category_short_name: 'FinnGen Genitourinary system',
            pvalue: '0.000151570655403801',
            stats: {
                no_samples: 12300,
                no_aa_genotypes: 100,
                no_ab_genotypes: 200,
                no_bb_genotypes: 300,
                maf: 13.33,
                ab_or_bb_genotypes: 14.34,
                bb_genotypes: 44.44,
                effect_size: 54,
                effect_size_standard_error: 100,
                effect_size_lci: 20.22,
                effect_size_uci: 23.23,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                gene_name: 'AAAS',
            },
        },
        {
            phenotype_name: '41202#C508#C50.8 Overlapping lesion of breast',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_name: 'Chapter XIV Diseases of the genitourinary system',
            category_short_name: 'FinnGen Genitourinary system',
            pvalue: '0.000151570655403801',
            stats: {
                no_samples: 12300,
                no_aa_genotypes: 100,
                no_ab_genotypes: 200,
                no_bb_genotypes: 300,
                maf: 13.33,
                ab_or_bb_genotypes: 14.34,
                bb_genotypes: 44.44,
                effect_size: 54,
                effect_size_standard_error: 100,
                effect_size_lci: 20.22,
                effect_size_uci: 23.23,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                gene_name: 'AAAS',
            },
        },
    ], 1),
    [collapsingModelsResponseMock[2].id]: generateData([
        {
            phenotype_name: '41202#C508#C50.8 Overlapping lesion of breast',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_name: 'Chapter XIV Diseases of the genitourinary system',
            category_short_name: 'FinnGen Genitourinary system',
            pvalue: '0.000151570655403801',
            stats: {
                no_samples: 12300,
                no_aa_genotypes: 100,
                no_ab_genotypes: 200,
                no_bb_genotypes: 300,
                maf: 13.33,
                ab_or_bb_genotypes: 14.34,
                bb_genotypes: 44.44,
                effect_size: 0,
                effect_size_standard_error: 100,
                effect_size_lci: 20.22,
                effect_size_uci: 23.23,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                gene_name: 'AAAS',
            },
        },
        {
            phenotype_name: '41202#C508#C50.8 Overlapping lesion of breast',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_name: 'Chapter XIV Diseases of the genitourinary system',
            category_short_name: 'FinnGen Genitourinary system',
            pvalue: '0.000151570655403801',
            stats: {
                no_samples: 12300,
                no_aa_genotypes: 100,
                no_ab_genotypes: 200,
                no_bb_genotypes: 300,
                maf: 13.33,
                ab_or_bb_genotypes: 14.34,
                bb_genotypes: 44.44,
                effect_size: -54,
                effect_size_standard_error: 100,
                effect_size_lci: 20.22,
                effect_size_uci: 23.23,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                gene_name: 'AAAS',
            },
        },
        {
            phenotype_name: '41202#C508#C50.8 Overlapping lesion of breast',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_name: 'Chapter XIV Diseases of the genitourinary system',
            category_short_name: 'FinnGen Genitourinary system',
            pvalue: '0.000151570655403801',
            stats: {
                no_samples: 12300,
                no_aa_genotypes: 100,
                no_ab_genotypes: 200,
                no_bb_genotypes: 300,
                maf: 13.33,
                ab_or_bb_genotypes: 14.34,
                bb_genotypes: 44.44,
                effect_size: 54,
                effect_size_standard_error: 100,
                effect_size_lci: 20.22,
                effect_size_uci: 23.23,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                gene_name: 'AAAS',
            },
        },
    ], 1),
    [collapsingModelsResponseMock[3].id]: [],
    [collapsingModelsResponseMock[4].id]: [],
    [collapsingModelsResponseMock[5].id]: [],
};
// Associations
export const variantBinaryAssociationResponseMock = (variantBinaryCollectionResponseMock[collapsingModelsIds[0]][0]);
export const variantContinuousAssociationResponseMock = (variantContinuousCollectionResponseMock[collapsingModelsIds[0]][0]);
// Response
export const variantDataResponseMock = {
    variant_id: variantIdMock,
    variant_name: variantNameMock,
    variant_type: variantTypeMock,
    gene_id: geneIdMock,
    gene_name: geneNameMock,
    traits_type: ETraitsTypes.Binary,
};
export const getEmptyVariantResponse = (traits_type = ETraitsTypes.Binary) => (Object.assign(Object.assign({}, variantDataResponseMock), { traits_type: traits_type || ETraitsTypes.Binary, associations: {} }));
export const variantBinaryResponseMock = (Object.assign(Object.assign({}, getEmptyVariantResponse()), { associations: variantBinaryCollectionResponseMock }));
export const variantContinuousResponseMock = (Object.assign(Object.assign({}, getEmptyVariantResponse(ETraitsTypes.Continuous)), { associations: variantContinuousCollectionResponseMock }));
// Fetch mocks
export const FinnGenBinaryRequestParams = {
    datasetVersionId: FinnGenDatasetVersionId,
    variantId: variantIdMock,
    variantName: variantNameMock,
    traitsType: ETraitsTypes.Binary,
};
export const FinnGenContinuousRequestParams = {
    datasetVersionId: FinnGenDatasetVersionId,
    variantId: variantIdMock,
    variantName: variantNameMock,
    traitsType: ETraitsTypes.Continuous,
};
export const UKBBinaryRequestParams = {
    datasetVersionId: UKBDatasetVersionId,
    variantId: variantIdMock,
    variantName: variantNameMock,
    traitsType: ETraitsTypes.Binary,
};
export const UKBContinuousRequestParams = {
    datasetVersionId: UKBDatasetVersionId,
    variantId: variantIdMock,
    variantName: variantNameMock,
    traitsType: ETraitsTypes.Continuous,
};
const mockedURL = {
    [createMockedURL(variantResourceVLR, FinnGenBinaryRequestParams)]: {
        full: variantBinaryResponseMock,
        empty: getEmptyVariantResponse(),
    },
    [createMockedURL(variantResourceVLR, FinnGenContinuousRequestParams)]: {
        full: variantContinuousResponseMock,
        empty: getEmptyVariantResponse(ETraitsTypes.Continuous),
    },
    [createMockedURL(variantResourceVLR, UKBBinaryRequestParams)]: {
        full: variantBinaryResponseMock,
        empty: getEmptyVariantResponse(),
    },
    [createMockedURL(variantResourceVLR, UKBContinuousRequestParams)]: {
        full: variantContinuousResponseMock,
        empty: getEmptyVariantResponse(ETraitsTypes.Continuous),
    },
};
export const fixtures = getMock(mockedURL);
