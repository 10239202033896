var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ELevelResult } from 'Shared/types';
import { logger, ELogEnvironment } from 'Common/utils/logger/logger';
import { HTTPClientError, HTTPServerError, HTTPNotFoundError } from 'API/errors';
import { QV, MTR, Datasets, PhenotypeStats, } from 'API/resources';
import { ActionError } from 'Store/errors';
import { RESET, SET_TABLE_SORT_BY, SET_TABLE_PAGE_SIZE, VARIANTS_FILTER, } from './consts';
import { fetchTranscripts } from './utils/fetchTranscripts/fetchTranscripts';
export const reset = (payload) => ({
    type: RESET,
    payload,
});
export const setTableSortBy = (columnId, sortType) => ({
    type: SET_TABLE_SORT_BY,
    payload: {
        columnId,
        sortType,
    },
});
export const setTablePageSize = (pageSize) => ({
    type: SET_TABLE_PAGE_SIZE,
    payload: {
        pageSize,
    },
});
export const setVariantsFilter = (pattern) => ({
    type: VARIANTS_FILTER,
    payload: {
        pattern,
    },
});
export const cleanup = createAction('qvView/cleanup');
export const setLoadingMessage = createAction('qvView/setLoadingMessage');
export const fetchAPIData = createAsyncThunk('qvView/fetchAPIData', (APIDataParams, { dispatch, rejectWithValue, signal }) => __awaiter(void 0, void 0, void 0, function* () {
    const { datasetVersionId, geneId, phenotypeId, phenotypicCategoryId, collapsingModelId, } = APIDataParams;
    try {
        const [variantsData, phenotypeStats, datasets, { pfamDomainsData, transcriptsData, },] = yield Promise.all([
            QV.resource.getData({ datasetVersionId }, {
                geneId,
                phenotypeId,
                phenotypicCategoryId,
                collapsingModelId,
            }, undefined, signal),
            PhenotypeStats.resource(ELevelResult.Gene)
                .getData({
                phenotypeId,
                datasetVersionId,
            }, undefined, undefined, signal),
            Datasets
                .getResource(0 /* Gene */)
                .getData({ resourceId: geneId }, undefined, undefined, signal),
            fetchTranscripts(geneId, signal, (message) => dispatch(setLoadingMessage(message))),
        ]);
        return {
            variantsData,
            pfamDomainsData,
            transcriptsData,
            datasets,
            APIDataParams,
            phenotypeStats,
        };
    }
    catch (err) {
        if (signal.aborted) {
            return rejectWithValue(ActionError.createSerialized(4 /* Aborted */));
        }
        if (err instanceof HTTPClientError || err instanceof HTTPNotFoundError) {
            return rejectWithValue(ActionError.createSerialized(0 /* NotFound */));
        }
        if (err instanceof HTTPServerError) {
            return rejectWithValue(ActionError.createSerialized(5 /* ServerError */));
        }
        logger.error(err.message, ELogEnvironment.All);
        return rejectWithValue(ActionError.createSerialized(3 /* Unknown */));
    }
}));
export const fetchMTRData = createAsyncThunk('qvView/fetchMTRData', (APIDataParams, { rejectWithValue, signal }) => __awaiter(void 0, void 0, void 0, function* () {
    const { geneId } = APIDataParams;
    if (!process.env.MTR_DATASET_VERSION_ID) {
        logger.warn('MTR_DATASET_VERSION_ID env var is not set: current dataset version id will be used for fetching MTR data');
    }
    try {
        const MTRData = yield MTR.resource.getData({ geneId }, undefined, undefined, signal);
        return MTRData;
    }
    catch (err) {
        if (signal.aborted) {
            return rejectWithValue(ActionError.createSerialized(4 /* Aborted */));
        }
        if (err instanceof HTTPClientError || err instanceof HTTPNotFoundError) {
            return rejectWithValue(ActionError.createSerialized(0 /* NotFound */));
        }
        if (err instanceof HTTPServerError) {
            return rejectWithValue(ActionError.createSerialized(5 /* ServerError */));
        }
        logger.error(err.message, ELogEnvironment.All);
        return rejectWithValue(ActionError.createSerialized(3 /* Unknown */));
    }
}));
