var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAPIData as fetchDatasetsData } from 'Datasets/store/actions';
import { encodeStringToBase64, isBase64String, isValidUuid, isValidV4Uuid, } from 'Common/utils';
import { logger, ELogEnvironment } from 'Common/utils/logger/logger';
import { CollapsingModelsService } from 'Common/services';
import { PHENOTYPE_ID_LOADING_MESSAGE } from 'QQView/consts';
import { HTTPClientError, HTTPServerError, HTTPNotFoundError } from 'API/errors';
import { CollapsingModels, Phenotype, PhenotypeId, PhenotypeStats, } from 'API/resources';
import { ActionError } from 'Store/errors';
import { setDataExists, setLoadingMessage } from './common';
export const setCollapsingModels = createAction('qqView/setCollapsingModels');
export const setPhenotypeStatistic = createAction('qqView/setPhenotypeStatistic');
export const fetchAPIData = createAsyncThunk('qqView/fetchAPIData', ({ datasetVersionId, encodedPhenotypeName, phenotypeId: phenotypeIdParam, levelResult, }, { rejectWithValue, signal, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        let phenotypeId = phenotypeIdParam;
        if (isValidUuid(encodedPhenotypeName)) {
            phenotypeId = encodedPhenotypeName;
        }
        else if (!isValidV4Uuid(phenotypeId)) {
            dispatch(setLoadingMessage(PHENOTYPE_ID_LOADING_MESSAGE));
            const phenotypeNameBase64 = isBase64String(encodedPhenotypeName)
                ? encodedPhenotypeName
                : encodeStringToBase64(encodedPhenotypeName);
            const phenotypeIdResponse = yield PhenotypeId.resource
                .getData({ phenotypeNameBase64 }, undefined, undefined, signal);
            phenotypeId = phenotypeIdResponse || '';
        }
        dispatch(setLoadingMessage('Loading datasets...'));
        const fetchDatasetsDataAction = dispatch(fetchDatasetsData({
            datasetType: 1 /* Phenotype */,
            resourceId: phenotypeId,
            datasetVersionId,
        }));
        /**
         * Instead of passing signal down to each action
         * abort them via callback invoked by the current signal
         */
        // eslint-disable-next-line no-param-reassign
        signal.onabort = () => {
            fetchDatasetsDataAction.abort();
        };
        const { payload: datasetsResult } = yield fetchDatasetsDataAction;
        const datasetsError = datasetsResult;
        if (datasetsError.type !== undefined) {
            throw new ActionError(datasetsError.type);
        }
        const phenotypeStatisticsRequest = PhenotypeStats
            .resource(levelResult)
            .getData({
            phenotypeId,
            datasetVersionId,
        }, undefined, undefined, signal);
        dispatch(setLoadingMessage('Loading collapsing models...'));
        const collapsingModelsData = yield CollapsingModels
            .resource
            .getData({ datasetVersionId });
        dispatch(setDataExists(true));
        const collapsingModelsIds = CollapsingModelsService.getCollapsingModelIds(collapsingModelsData, levelResult);
        const phenotypeRequest = Phenotype
            .resource(levelResult)
            .getData({
            phenotypeId,
            datasetVersionId,
        }, {
            collapsingModelId: collapsingModelsIds,
        }, undefined, signal);
        dispatch(setLoadingMessage('Loading Phenotype statistic...'));
        const [phenotypeStatistics, phenotypeAssociations,] = yield Promise.all([
            phenotypeStatisticsRequest,
            phenotypeRequest,
        ]);
        const actualModelIds = Object.keys(phenotypeAssociations.associations);
        const actualCollapsingModels = collapsingModelsData
            .filter((model) => actualModelIds.includes(model.id));
        dispatch(setCollapsingModels(actualCollapsingModels));
        dispatch(setPhenotypeStatistic(phenotypeStatistics));
    }
    catch (err) {
        if (signal.aborted) {
            return rejectWithValue(ActionError.createSerialized(4 /* Aborted */));
        }
        if (err instanceof HTTPClientError || err instanceof HTTPNotFoundError) {
            return rejectWithValue(ActionError.createSerialized(0 /* NotFound */));
        }
        if (err instanceof HTTPServerError) {
            return rejectWithValue(ActionError.createSerialized(5 /* ServerError */));
        }
        logger.error(err.message, ELogEnvironment.All);
        return rejectWithValue(ActionError.createSerialized(3 /* Unknown */));
    }
}));
