import { createSelector } from '@reduxjs/toolkit';
import { HIGH_IMPACT_LEVELS_BY_TYPE, MODERATE_IMPACT_LEVELS_BY_TYPE, MODIFIER_IMPACT_LEVELS_BY_TYPE } from 'Shared/components';
import { GeneAssociationService } from 'Common/services';
import { getValidatedPlotData } from 'Common/utils';
import { getTableMapper, getPlotDataFromCategories, } from 'GeneView/utils';
import { getState } from './common';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getSelectors = (traitsType) => {
    const getTraitsState = createSelector([getState], (state) => state[traitsType]);
    const getErrorType = createSelector([getTraitsState], ({ errorType }) => errorType);
    const getLoadingStatus = createSelector([getTraitsState], ({ loadingStatus }) => loadingStatus);
    const getLoadingMessage = createSelector([getTraitsState], ({ loadingMessage }) => loadingMessage);
    const getIsFiltering = createSelector([getTraitsState], ({ isFiltering }) => isFiltering);
    const getIsEmpty = createSelector([getTraitsState], ({ associations }) => associations.order.length === 0);
    const getTableSettings = createSelector([getTraitsState], ({ tableSettings }) => tableSettings);
    const getFilters = createSelector([getTraitsState], ({ filters }) => filters);
    const getPhenotypes = createSelector(getTraitsState, ({ phenotypes }) => phenotypes);
    const getPhenotypesNames = createSelector(getPhenotypes, (phenotypes) => Object.keys(phenotypes));
    const getCollapsingModels = createSelector(getTraitsState, ({ collapsingModels }) => collapsingModels);
    const getAssociations = createSelector([getTraitsState], ({ associations }) => associations);
    const getVariants = createSelector(getAssociations, ({ collection }) => GeneAssociationService.getVariantsNames(collection));
    const getCategories = createSelector([getTraitsState], ({ categories }) => categories);
    const getFilteredAssociationIds = createSelector([getTraitsState], ({ filteredAssociationIds }) => filteredAssociationIds);
    const getTableData = createSelector([getFilteredAssociationIds, getAssociations], (filteredAssociationIds, associations) => (filteredAssociationIds.map((id) => associations.collection[id])));
    const getAllTableData = createSelector(getAssociations, ({ collection }) => Object.values(collection));
    const getDataPointsByPhenotypicCategoriesMapper = createSelector([getTableData], (tableData) => getTableMapper(getValidatedPlotData(tableData)));
    const getAllDataPointsByPhenotypicCategoriesMapper = createSelector([getAllTableData], (tableData) => getTableMapper(getValidatedPlotData(tableData)));
    const getFilteredCategories = createSelector(getFilters, ({ categories }) => categories);
    const getPlotData = createSelector([getFilteredCategories, getDataPointsByPhenotypicCategoriesMapper], (categories, dataPointsMapper) => (getPlotDataFromCategories(traitsType, categories, dataPointsMapper)));
    const getAllPlotData = createSelector([getCategories, getAllDataPointsByPhenotypicCategoriesMapper], (categories, dataPointsMapper) => (getPlotDataFromCategories(traitsType, categories, dataPointsMapper)));
    const getConsequenceTypes = createSelector(getState, (state) => state.consequenceTypes);
    const getConsequenceTypeFilterHierarchy = createSelector(getConsequenceTypes, (consequenceTypes) => {
        const consequenceTypeFilter = consequenceTypes.map((str) => ({
            id: str,
            label: str,
        }));
        const consequenceTypeCategories = consequenceTypeFilter.reduce((categories, consequenceType) => {
            if (consequenceType.id in HIGH_IMPACT_LEVELS_BY_TYPE) {
                categories.highImpactChildren.push(consequenceType);
            }
            else if (consequenceType.id in MODERATE_IMPACT_LEVELS_BY_TYPE) {
                categories.moderateImpactChildren.push(consequenceType);
            }
            else if (consequenceType.id in MODIFIER_IMPACT_LEVELS_BY_TYPE) {
                categories.modifierImpactChildren.push(consequenceType);
            }
            else {
                categories.lowImpactChildren.push(consequenceType);
            }
            return categories;
        }, {
            lowImpactChildren: [],
            highImpactChildren: [],
            moderateImpactChildren: [],
            modifierImpactChildren: [],
        });
        return [
            {
                id: 'High Impact',
                label: 'High Impact',
                children: consequenceTypeCategories.highImpactChildren,
            },
            {
                id: 'Moderate Impact',
                label: 'Moderate Impact',
                children: consequenceTypeCategories.moderateImpactChildren,
            },
            {
                id: 'Modifier Impact',
                label: 'Modifier Impact',
                children: consequenceTypeCategories.modifierImpactChildren,
            },
            {
                id: 'Low Impact',
                label: 'Low Impact',
                children: consequenceTypeCategories.lowImpactChildren,
            },
        ];
    });
    const getFiltersData = createSelector(getFilters, getCategories, getCollapsingModels, getPhenotypesNames, getVariants, getConsequenceTypes, (filters, categories, collapsingModels, phenotypes, variants, consequenceTypes) => ({
        categories,
        collapsingModels,
        filters,
        phenotypes,
        variants,
        consequenceTypes,
    }));
    return {
        getVariants,
        getIsFiltering,
        getIsEmpty,
        getFiltersData,
        getTableData,
        getPlotData,
        getTableSettings,
        getLoadingStatus,
        getLoadingMessage,
        getErrorType,
        getAllTableData,
        getAllPlotData,
        getConsequenceTypeFilterHierarchy,
    };
};
