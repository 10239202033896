var _a;
import { COOKIE_POLICY_ID } from './env';
/**
 * Email to CGR Informatics Support
 */
export const CGR_INFORMATICS_SUPPORT_EMAIL = 'CGR-Informatics-Support@astrazeneca.com';
/**
 * Email to helpdesk Astrazeneca
 */
export const HELP_DESK_EMAIL = 'CGR_AI_Help@astrazeneca.com';
/**
 * Link to Global Biobank Meta
 */
export const GBMI_URL = 'https://www.globalbiobankmeta.org/';
/**
 * Link to SAIGE UK Biobank
 */
export const SAIGE_UK_BIOBANK_URL = 'http://pheweb.sph.umich.edu/SAIGE-UKB/';
/**
 * Link to SAIGE full gene results
 */
export const SAIGE_FULL_GENE_RESULTS_URL = 'https://pheweb.org/UKB-SAIGE/gene/';
/**
 * Link to Gene Atlas
 */
export const GENE_ATLAS_URL = 'http://geneatlas.roslin.ed.ac.uk/';
/**
 * Link to EBI GWAS catalog
 */
export const EBI_GWAS_CATALOG = 'https://www.ebi.ac.uk/gwas/';
/**
 * Link to CVRM HTV Report
 */
export const CVRM_HTV_REPORT_URL = 'https://htv.scp.astrazeneca.net/';
/**
 * Link to Clin Var archive
 */
export const CLIN_VAR_URL = 'https://www.ncbi.nlm.nih.gov/clinvar/';
/**
 * Link to Nucleic Acids Research
 */
export const NUCLEIC_ACIDS_RESEARCH_URL = 'https://academic.oup.com/nar/article/46/D1/D1062/4641904/';
/**
 * Link to legal notice
 */
export const LEGAL_NOTICE_URL = 'https://www.astrazeneca.com/legal-notice-and-terms-of-use.html';
/**
 * Link to R&I Target Report
 */
export const R_I_TARGET_REPORT_URL = 'https://targetreport.scp.astrazeneca.net';
/**
 * Link to 3D Protein Structure
 */
export const PROTEIN_STRUCTURE_URL = 'https://datasci-dev.cgr.astrazeneca.net/protein-viewer/';
/**
 * Link to CRISPR catalogue
 */
export const CRISPR_CATALOGUE_URL = 'https://crispr.astrazeneca.net/';
/**
 * Link to CRISPR catalogue API
 */
export const CRISPR_CATALOGUE_API_URL = 'https://fgc-crispr-db-api.astrazeneca.net/v2/sso/search';
/**
 * Link to Competitive Intelligence (CI)
 */
export const COMPETITIVE_INTELLIGENCE_URL = 'https://datasci-dev.cgr.astrazeneca.net/';
/**
 * Link to Proteomics Atlas
 */
export const CGR_PROTEOMICS_ATLAS_URL = 'https://proteomicsatlas.cgr.astrazeneca.net/';
/**
 * Link to Pages SCP
 */
export const PAGES_SCP_ASTRAZENECA_URL = 'https://pages.scp.astrazeneca.net/';
/**
 * Link to Mantis ML
 */
export const MANTIS_ML_URL = `${PAGES_SCP_ASTRAZENECA_URL}cgr/mantisml/`;
/**
 * Link to Proteogenomics Portal
 */
export const PROTEOGENOMICS_PORTAL_URL = `${PAGES_SCP_ASTRAZENECA_URL}cgr/pqtl-browser/`;
/**
 * Link to Smart-phenotypes
 */
export const SMART_PHENOTYPES_URL = 'https://datasci-dev.cgr.astrazeneca.net/smart-phenotypes/';
/**
 * Link to Columbus
 */
export const COLUMBUS_CGR_URL = (_a = process.env.COLUMBUS_CGR_URL) !== null && _a !== void 0 ? _a : 'https://columbus.cgr.astrazeneca.net/';
/**
 * Link to OncMTR
 */
export const ONC_MTR_URL = 'https://astrazeneca-cgr-publications.github.io/OncMTR-Viewer/';
/**
 * Link to Druggability predictions
 */
export const DRUGGABILITY_PREDICTIONS_URL = 'https://astrazeneca-cgr-publications.github.io/DrugnomeAI/';
/**
 * Link to Digital Object Identifier Page
 */
export const PUBLICATION_URL = 'https://www.nature.com/articles/s41586-021-03855-y';
/**
 * Link to privacy notice
 */
export const PRIVACY_NOTICE_URL = 'https://www.globalprivacy.astrazeneca.com/';
/**
 * Link to UK Biobank notice
 */
export const BIOBANK_URL = 'https://www.ukbiobank.ac.uk/enable-your-research/apply-for-access';
/**
 * Link to FinnGen website
 */
export const FINNGEN_URL = 'https://www.finngen.fi/en';
/**
 * Link to PUBMED website
 */
export const PUBMED_URL = 'https://pubmed.ncbi.nlm.nih.gov/';
/**
 * Link to OMIM website
 */
export const OMIM_URL = 'https://www.omim.org/';
/**
 * Link to HGMD website
 */
export const HGMD_URL = 'http://www.hgmd.org';
/**
 * Link to article on springer.com
 */
export const SPRINGER_ARTICLE_LINK = 'https://link.springer.com/article/10.1007/s00439-017-1779-6';
/**
  * Link to Cookie Policy page
  */
export const COOKIE_NOTICE_URL = COOKIE_POLICY_ID
    ? `//policy.cookiereports.com/${COOKIE_POLICY_ID}-en-gb.html`
    : null;
/**
  * Link to License file
  */
export const DATA_LICENSE_URL = 'https://az.box.com/s/0lm0p95mdppv1c271c9fge8u0ilyos6j';
/**
 * Link to doi.org in about us page
 */
export const DOI_ORG_URL = 'https://doi.org';
export const DOI_ORG_URL_2023 = `${DOI_ORG_URL}/10.1016/j.ajhg.2023.02.002`;
export const DOI_ORG_URL_2022 = `${DOI_ORG_URL}/10.1101/2022.10.09.511476`;
export const DOI_ORG_RARE_VARIANT_ASSOCIATIONS_WITH_PLASMA_PROTEIN_LEVELS_UKB_2023 = `${DOI_ORG_URL}/10.1038/s41586-023-06547-x`;
export const DOI_ORG_WHOLE_GENOME_SEQUENCING_OF_HALF_A_MILLION_UK_BIOBANK_PARTICIPANTS = `${DOI_ORG_URL}/10.1101/2023.12.06.23299426`;
export const DOI_ORG_GENETIC_ARCHITECTURE_OF_TELOMERE_LENGTH_IN_UK_BIOBANK_SEQUENCES = `${DOI_ORG_URL}/10.1101/2023.09.18.23295715`;
export const DOI_ORG_PHENOME_WIDE_IDENTIFICATION_OF_THERAPEUTIC_GENETIC_TARGETS = `${DOI_ORG_URL}/10.1126/sciadv.adj1424`;
