import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment } from 'react';
import { VERSIONS_INTERNAL, VERSIONS_PUBLIC, VERSION_SECTION_TITLE, } from '../../consts';
import { ParagraphBolded, ParagraphWithMargin } from '../../containers';
import Title from '../Title';
import DescriptionMapper from '../DescriptionMapper';
const VersionSection = ({ isPublic = false, }) => {
    const data = isPublic
        ? VERSIONS_PUBLIC
        : VERSIONS_INTERNAL;
    return (_jsxs(_Fragment, { children: [_jsx(Title, { label: VERSION_SECTION_TITLE }, void 0), data.map(({ key, title, description }) => (_jsxs(Fragment, { children: [_jsx(ParagraphBolded, { children: title }, void 0), _jsx(ParagraphWithMargin, { children: _jsx(DescriptionMapper, { addBreaks: true, data: description, keyPrefix: key }, void 0) }, void 0)] }, key)))] }, void 0));
};
export default VersionSection;
