import { DASHBOARD_PVALUE_THRESHOLD_TEXT } from 'Common/consts';
import { ELevelResult } from 'Shared/types';
export const DATASET_METHODS_200K_PUBLIC = 'We used the subset of the ~200,000 exome sequenced participants released by the UK Biobank in October 2020 that are high quality, predominantly unrelated, and of European ancestry (n = 177,882) to evaluate the association between rare protein-coding variants with 10,533 binary and 1,419 continuous phenotypes using a gene-level phenome-wide association study. This portal contains the subset of gene-level associations for which p ≤ 0.1. Variant-level associations and qualifying variants are not currently available for the 200K dataset. Full methods are described in the publication.';
export const DATASET_METHODS_450K_PUBLIC = 'We used the subset of the ~450,000 exome sequenced participants released by the UK Biobank that are high quality, predominantly unrelated, and of European ancestry (n = 394,695) to evaluate the association between protein-coding variants with ~15.5K binary and ~1.5K continuous phenotypes using variant-level and gene-level phenome-wide association studies. A small number of potentially sensitive phenotypes have been excluded. This portal contains the subset of variant-level and gene-level associations for which p ≤ 0.1. Only variants identified in at least 30 samples were included in the variant-level analysis. Qualifying variants are available for the subset of gene-level associations for which p ≤ 0.005 and there are more than 100 cases (for binary traits). Full methods are described in the publication.';
export const DATASET_METHODS_300K_PUBLIC = 'We used the subset of the ~300,000 exome sequenced participants released by the UK Biobank that are high quality, predominantly unrelated, and of European ancestry (n = 269,171) to evaluate the association between protein-coding variants with ~17K binary and ~1,4K continuous phenotypes using variant-level and gene-level phenome-wide association studies. A small number of potentially sensitive phenotypes have been excluded. This portal contains the subset of variant-level associations for which p ≤ 0.0001 and the subset of gene-level associations for which p ≤ 0.1. Only variants identified in at least 30 samples were included in the variant-level analysis. Qualifying variants are available for the subset of gene-level associations for which p ≤ 0.005 and there are more than 100 cases (for binary traits). Full methods are described in the publication.';
export const DATASET_METHODS_450K_INTERNAL = 'We used the subset of the ~450,000 exome sequenced participants released by the UK Biobank that are high quality, predominantly unrelated, and of European ancestry (n = 394,695) to evaluate the association between protein-coding variants with ~15.5K binary and ~1.5K continuous phenotypes using variant-level and gene-level phenome-wide association studies. These phenotypes include some AZ-defined ‘smart phenotypes’, NMR-based blood metabolites generated on a subset of 121,695 UK Biobank participants as well as telomere length. This portal contains the subset of variant-level associations for which p ≤ 0.0001 and the subset of gene-level associations for which p ≤ 0.1. Qualifying variants are available for the subset of gene-level associations for which p ≤ 0.005. Full methods are described in the publication.';
export const DATASET_METHODS_FINNGEN = 'These data are the results of a Phenome-Wide Association Study (PheWAS) performed by the FinnGen study on FinnGen genotyping data (version R6). They used 260,405 genotyped Finnish individuals to evaluate the association between common and Finnish-enriched variants with 2861 binary phenotypes. The study comprises variant level (common variants) association statistics. They used SAIGE, which is a mixed model logistic regression package, for each variant with a minimum allele count of 5.';
export const GENE_TOLERANCE_ERROR_MESSAGE_TEXT = 'Tolerance scores are not available for this gene';
export const TABLE_SECTION_ERROR_MESSAGE_TEXT = `There are no associations with p ≤ ${DASHBOARD_PVALUE_THRESHOLD_TEXT} for this gene`;
export const PLOT_SECTION_ERROR_MESSAGE_TEXT = `There are no associations with p ≤ ${DASHBOARD_PVALUE_THRESHOLD_TEXT} for this gene`;
export const initialLevelResultsCollection = {
    [ELevelResult.Gene]: true,
    [ELevelResult.Variant]: false,
};
export const levelResultNames = {
    [ELevelResult.Gene]: 'gene',
    [ELevelResult.Variant]: 'variant',
};
export const TITLE_CRV = 'Clinically relevant variants';
export const TITLE_PAV = 'Publicly available common variant associations';
export const TITLE_OMIM = 'OMIM';
export const TOOLTIP_OMIM = 'OMIM reported gene-phenotype relationships';
export const ZERO_COUNT_TOOLTIP_OMIM = 'No gene-phenotype relationships reported in OMIM for this gene';
export const TITLE_HGMD = 'HGMD';
export const TOOLTIP_HGMD = 'HGMD pathogenicity catalog';
export const ZERO_COUNT_TOOLTIP_HGMD = 'No gene-phenotype relationships reported in HGMD for this gene';
export const TITLE_CLINVAR = 'ClinVar';
export const TOOLTIP_CLINVAR = 'ClinVar pathogenicity catalog';
export const ZERO_COUNT_TOOLTIP_CLINVAR = 'No gene-phenotype relationships reported in ClinVar for this gene';
export const TITLE_EBIGWAS = 'NHGRI-EBI GWAS Catalog';
export const TOOLTIP_EBIGWAS = 'NHGRI-EBI Catalog of human genome-wide association studies';
export const ZERO_COUNT_TOOLTIP_EBIGWAS = 'No gene-phenotype relationships reported in NHGRI-EBI GWAS Catalog for this gene';
export const TITLE_GBMI = 'GBMI';
export const TOOLTIP_GBMI = 'Global Biobank Meta-Analysis Initiative results';
export const ZERO_COUNT_TOOLTIP_GBMI = 'Global Biobank Meta-Analysis Initiative results are not available for this gene';
export const FetchErrorMessages = {
    OMIM: 'Loading OMIM data failed, please try again later',
    HGMD: 'Loading HGMD data failed, please try again later',
    ClinVar: 'Loading ClinVar data failed, please try again later',
    'NHGRI-EBI GWAS Catalog': 'Loading NHGRI-EBI Gwas Catalog data failed, please try again later',
    GBMI: 'Loading GBMI data failed, please try again later',
};
export const LOADING_POSTFIX = '_LOADING';
export const ERROR_POSTFIX = '_ERROR';
